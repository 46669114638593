@charset 'utf-8';

.#{$pretty--class-name} * {
  box-sizing: border-box;
}

//Throw error on invalid input types.
.#{$pretty--class-name} input:not([type='checkbox']):not([type='radio']) {
  display: none;

  @if $pretty--debug {
    + *:after {
      content: $pretty--err-message;
      border: 1px solid #dedede;
      border-left: 3px solid #d9534f;
      padding: 9px;
      font-size: 1em;
      font-weight: 600;
      color: #d9534f;
      position: absolute;
      z-index: 3;
      background: #fbfbfb;
      top: 0;
      left: 0;
    }
  }
}

.#{$pretty--class-name} {
  position: relative;
  display: inline-block;
  margin-right: 1em;
  white-space: nowrap;
  line-height: 1;

  input {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: $pretty--z-index-front;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .state {
    label {
      position: initial;
      display: inline-block;
      font-weight: normal;
      margin: 0;
      text-indent: $pretty--label-text-offset;
      min-width: $pretty--box-size;

      &:before,
      &:after {
        content: '';
        width: $pretty--box-size;
        height: $pretty--box-size;
        display: block;
        box-sizing: border-box;
        border-radius: 0;
        border: 1px solid transparent;
        z-index: $pretty--z-index-back;
        position: absolute;
        left: 0;
        top: $pretty-top-offset;
        background-color: transparent;
      }

      &:before {
        border-color: $pretty--color-default;
      }
    }

    &.p-is-hover,
    &.p-is-indeterminate {
      display: none;
    }
  }
}