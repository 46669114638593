.#{$pretty--class-name}.p-round {
  .state label {
    &:before,
    &:after {
      border-radius: 100%;
    }
  }

  &.p-icon .state .icon {
    border-radius: 100%;
    overflow: hidden;

    &:before {
      transform: scale(0.8);
    }
  }
}
