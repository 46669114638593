.#{$pretty--class-name}.p-svg {
  .state {
    .svg {
      position: absolute;
      font-size: 1em;
      width: $pretty--box-size;
      height: $pretty--box-size;
      left: 0;
      z-index: $pretty--z-index-between;
      text-align: center;
      line-height: normal;
      top: $pretty-top-offset;
      border: 1px solid transparent;
      opacity: 0;
    }

    svg {
      margin: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      line-height: 1;
    }
  }

  input:checked ~ .state .svg {
    opacity: 1;
  }
}